// src/components/AdminSection.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Box, Heading, List, ListItem, VStack, Text } from '@chakra-ui/react';
import { getArrayOfInfo, initializePostCounts } from '../utils/dashboardDetails';

const AdminSection = () => {
  const [countThere, setCountThere] = useState(0);
  const [countHere, setCountHere] = useState(0);
  
  useEffect(() => {
    const fetchCounts = async () => {
      await initializePostCounts();
      const info = getArrayOfInfo();
      setCountThere(info.CountThere);
      setCountHere(info.CountHere);
    };

    fetchCounts();
  }, []);

return (
  <VStack>
    <Box color="white">
      <Heading as="h1" size="lg" mb={4}>Admin Section</Heading>
      <nav>
        <List spacing={3}>
          <ListItem><Link to="/admin/characters" _hover={{ color: 'gray.300' }}>Characters</Link></ListItem>
          <ListItem><Link to="/admin/chats" _hover={{ color: 'gray.300' }}>Chats</Link></ListItem>
          <ListItem><Link to="/admin/messages" _hover={{ color: 'gray.300' }}>Messages</Link></ListItem>
          <ListItem><Link to="/admin/commodities" _hover={{ color: 'gray.300' }}>Commodities</Link></ListItem>
          <ListItem><Link to="/admin/chat_gpt_prompts" _hover={{ color: 'gray.300' }}>Chat GPT Prompts</Link></ListItem>
          <ListItem><Link to="/admin/news_narratives" _hover={{ color: 'gray.300' }}>News Narratives</Link></ListItem>
          <ListItem><Link to="/admin/news" _hover={{ color: 'gray.300' }}>News</Link></ListItem>
          <ListItem><Link to="/admin/players" _hover={{ color: 'gray.300' }}>Players</Link></ListItem>
          <ListItem><Link to="/admin/search_canon" _hover={{ color: 'gray.300' }}>Search Canon</Link></ListItem>
          <ListItem><Link to="/admin/canon_articles" _hover={{ color: 'gray.300' }}>List All Canon Articles</Link></ListItem>
          <ListItem><Link to="/admin/canon_draft_articles" _hover={{ color: 'gray.300' }}>List Draft Canon Articles</Link></ListItem>
        </List>
      </nav>
    </Box>
    <Box color="white">
      <Heading as="h1">Dashboard</Heading>
      <Text>Canon Articles in WP: {countThere}</Text>
      <Text>Canon Articles in DB: {countHere}</Text>
    </Box>
  </VStack>
);
}



export default AdminSection;
