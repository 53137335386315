import React from 'react';
import { Box, Button, HStack, VStack } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import formatDate from '../utils/formatDate';
import { stripHtmlAndDecode } from '../utils/textUtils';

const SearchResultItem = ({ result, onOpen, setSelectedResult }) => {
  return (
    <HStack pt={4} pb={4} borderWidth="1px" borderRadius="md">
      <Box flex="2">
        {result.og_image_url ? (
          <img src={result.og_image_url} alt={result.title} />
        ) : (
          <Box color="white" textAlign="center">No Image</Box>
        )}
      </Box>
      <Box flex="6">
        <Box color="teal.300" fontWeight="bold" fontSize="xl">
          {stripHtmlAndDecode(result.title)}
        </Box>
        <Box fontSize="sm" color="gray.500">Last Modified: {formatDate(result.last_modified)}</Box>
        <Box color="white" mt={2}>{stripHtmlAndDecode(result.excerpt)}</Box>
      </Box>
      <Box flex="2" borderLeft="1px solid gray" display="grid" justifyContent="center" justifySelf="center" alignItems="center">
        <VStack>
          <Button
            colorScheme="teal"
            size="xs"
            onClick={() => {
              setSelectedResult(result);
              onOpen();
            }}
          >
            View
          </Button>
          <Link to={`/admin/search_canon/edit/${result.id}`}>
            <Button colorScheme="blue" size="xs">
              Edit
            </Button>
          </Link>
          { !result.og_image_url && (
            <Link to={`/admin/search_canon/details/${result.id}`}>
              <Button colorScheme="orange" size="xs">Generate Image</Button>
            </Link>
          )}
        </VStack>
      </Box>
    </HStack>
  );
};

export default SearchResultItem;