const formatDate = (date) => {
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  const formattedDate = new Date(date).toLocaleDateString('en-US', options);

  // Add ordinal suffix to the day
  const day = new Date(date).getDate();
  const ordinalSuffix = (day) => {
    if (day > 3 && day < 21) return 'th'; // Handle special case
    switch (day % 10) {
      case 1: return 'st';
      case 2: return 'nd';
      case 3: return 'rd';
      default: return 'th';
    }
  };

  return formattedDate.replace(/\d+/, day + ordinalSuffix(day));
};

export default formatDate;