import React, { useState } from 'react';
import { Input, Button, Box, VStack, HStack, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, useDisclosure, Spinner } from '@chakra-ui/react';
import searchCanon from '../utils/searchCanon';
import { stripHtmlAndDecode } from '../utils/textUtils'; // Import the new utility function
import SearchResultItem from './SearchResultItem';

const SearchCanon = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedResult, setSelectedResult] = useState(null);

  const handleSearch = async () => {
    setIsLoading(true);
    try {
      const data = await searchCanon(query);
      setResults(data);
    } catch (error) {
      console.error('Search failed:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box backgroundColor="black" p={4}>
      <VStack spacing={4}>
        <Input
          placeholder="Enter search term"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
        color="white"
        />
        <Button onClick={handleSearch} colorScheme="teal" isDisabled={isLoading}>
          {isLoading ? <Spinner size="sm" /> : 'Search'}
        </Button>
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{selectedResult ? stripHtmlAndDecode(selectedResult.title) : ''}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
            <Box 
              color="black" 
              dangerouslySetInnerHTML={{ __html: selectedResult ? selectedResult.content.replace(/<a /g, '<a target="_blank" ') : '' }}
              sx={{
                a: {
                  color: 'teal.500',
                  textDecoration: 'underline',
                  fontWeight: 'bold',
                  _hover: {
                    color: 'teal.700',
                  },
                },
              }}
            />
            </ModalBody>
          </ModalContent>
        </Modal>
        {/* Render search results here */}
        <Box>
          {results.map((result, index) => (
            <SearchResultItem
              key={index}
              result={result}
              onOpen={onOpen}
              setSelectedResult={setSelectedResult}
            />
          ))}
        </Box>
      </VStack>
    </Box>
  );
};

export default SearchCanon;