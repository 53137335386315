import React, { useState, useEffect } from 'react';
import {Box, VStack, useDisclosure, Spinner, Text} from '@chakra-ui/react';
import listCanonDrafts from '../utils/listCanonDrafts';
import SearchResultItem from './SearchResultItem';

const CanonDraftArticles = () => {
  useEffect(() => {
    handleList();
  }, []);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [results, setResults] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedResult, setSelectedResult] = useState(null);

  const handleList = async () => {
    try {
      const data = await listCanonDrafts();
      setResults(data);
    } catch (error) {
      console.error('Load of draft articles failed:', error);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <VStack alignItems="center">
        <Spinner size="lg" color="teal.500"/>
        <Text>Loading...</Text>
      </VStack>
    );
  }

  return (
    <Box backgroundColor="black" p={4}>
      <VStack spacing={4}>
        <Box>
          {results.map((result, index) => (
            <SearchResultItem
              key={index}
              result={result}
              onOpen={onOpen}
              setSelectedResult={setSelectedResult}
            />
          ))}
        </Box>
      </VStack>
    </Box>
  );
};

export default CanonDraftArticles;