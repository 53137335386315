import React, { useEffect, useState } from 'react';
import { Box, Heading, Text, Alert, AlertIcon, AlertTitle, AlertDescription, Button, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Radio, RadioGroup, Stack } from '@chakra-ui/react';
import { jwtDecode } from 'jwt-decode';

import fetchUserInfo from '../utils/fetchUserInfo';
import saveSelectedBank from '../utils/saveSelectedBank';
import fetchBanks from '../utils/fetchBanks';

const Console = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [potentialBanks, setPotentialBanks] = useState([]);
  const [userInfo, setUserInfo] = useState("");
  const [userId, setUserId] = useState("");
  const [selectedBank, setSelectedBank] = useState(""); // New state for selected bank
  const [isAdmin, setIsAdmin] = useState(false); // New state for admin status

    // Move fetchUserData to component scope
    const fetchUserData = async () => {
      try {
        const data = await fetchUserInfo(userId);
        // console.log('User information:', data);
        setUserInfo(data);
      } catch (error) {
        console.error('Failed to fetch user information:', error);
      }
    };
  
    // Move fetchBankData to component scope
    const fetchBankData = async () => {
      try {
        const banks = await fetchBanks();
        // console.log('Fetched banks:', banks);
        setPotentialBanks(banks);
      } catch (error) {
        console.error('Failed to fetch banks:', error);
      }
    };
  

  // Adding the function to handle saving the selected bank.
  const handleSaveBank = async () => {
    try {
      await saveSelectedBank(userId, selectedBank);
      // console.log('Bank saved successfully');
      onClose();
      // Refresh user information and banks
      fetchUserData();
      fetchBankData();
    } catch (error) {
      console.error('Failed to save bank:', error);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem('nexus_login_token');
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        setUserId(decodedToken.sub);
      } catch (error) {
        console.error("Invalid token:", error);
        // Optionally, clear the invalid token from localStorage
        localStorage.removeItem('nexus_login_token');
      }
    } else {
      console.error('No token found');
    }
  }, []);
  

  useEffect(() => {
    if (userId) {
      fetchUserData();
    }
  }, [userId]);

  useEffect(() => {
    fetchBankData();
  }, []);

  return (
    <Box bg="#FFFFFF" height="80vh" maxHeight="80vh" textAlign="center" py={10} px={6}>
      <Heading>{userInfo.given_name} {userInfo.family_name}'s Console</Heading>
      <Text></Text>
      {/* Display user information */}
      {userInfo && (
        <div>
          {/* Add more user fields as needed */}
          {userInfo.bank_account ? (
            <Box borderWidth="1px" borderRadius="lg" overflow="hidden" p={4} mb={4} maxWidth="90%" mx="auto">
              <Text fontWeight="bold">{userInfo.bank_account.bank_name}</Text>
              <Text>${userInfo.bank_account.balance}</Text>
            </Box>
          ) : (
            <Alert status="warning" mt={4}>
              <AlertIcon />
              <AlertTitle mr={2}>No Bank Selected</AlertTitle>
              <AlertDescription>Please choose a bank.</AlertDescription>
              <Button colorScheme="teal" onClick={onOpen} ml={4}>
                Choose Bank
              </Button>
            </Alert>
          )}
        </div>
      )}
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Choose a Bank</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <RadioGroup onChange={setSelectedBank} value={selectedBank}>
            <Stack direction="column">
              {potentialBanks.map((bank, index) => (
                <Radio key={index} value={bank}>
                  {bank}
                </Radio>
              ))}
            </Stack>
          </RadioGroup>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={handleSaveBank}>
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  </Box>
  );
};

export default Console;
