import api from '../api/api';

/**
 * Saves the selected bank for the user to the API.
 * @param {string} userId - The ID of the user.
 * @param {string} bank - The selected bank to be saved.
 * @returns {Promise<object>} - A promise that resolves to the response from the API.
 */
const saveSelectedBank = async (userId, bank) => {
  try {
    const response = await api.post(`/players/${userId}/bank_accounts/specify_bank`, { bank });
    return response.data;
  } catch (error) {
    console.error('Error saving selected bank:', error);
    throw error;
  }
};

export default saveSelectedBank;