import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Heading, Text, Spinner, Textarea, Button } from '@chakra-ui/react';
import api from '../api/api';
import { stripHtmlAndDecode } from '../utils/textUtils'; // Import the utility function

const CanonResultDetails = () => {
  const { id } = useParams();
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(true);
  const [textareaContent, setTextareaContent] = useState(''); // New state for Textarea content
  const [isGenerating, setIsGenerating] = useState(false); // State for managing spinner visibility
const [imageUrl, setImageUrl] = useState(''); // New state for image URL
  const [title, setTitle] = useState(''); // New state for title
  const [resultId, setResultId] = useState(''); // New state for id
  const [models, setModels] = useState([]); // New state for models
const [selectedModel, setSelectedModel] = useState('5c232a9e-9061-4777-980a-ddc8e65647c6'); // State for selected model with default ID

  useEffect(() => {

    const fetchModels = async () => {
      try {
        const response = await api.get('/models');
        console.log('Models API response:', response.data); // Log the response data
        setModels(Array.isArray(response.data.models.custom_models) ? response.data.models.custom_models : []); // Ensure models is an array
      } catch (error) {
        console.error('Error fetching models:', error);
        alert('Failed to fetch models.');
      }
    };

    const fetchResultDetails = async () => {
      try {
        const response = await api.get(`/canon_post/${id}`);
        setResult(response.data);
        setTitle(response.data.title); // Set title state
        setResultId(response.data.id); // Set id state
        setTextareaContent(`${response.data.title}\n\n${stripHtmlAndDecode(response.data.content.replace(/\n/g, ' '))}`); // Initialize Textarea content

      } catch (error) {
        console.error('Error fetching result details:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchResultDetails(); // Fetch result details
    fetchModels(); // Fetch models
  }, [id]);



const handleSendData = async () => {
    setIsGenerating(true); // Show spinner
    const dataToSend = {
      id: resultId,
      content: textareaContent,
      model_id: selectedModel, // Add selected model ID
    };

    try {
      const response = await api.post('/prompts', dataToSend);
      const { message, prompt, canon_post_id, image_response } = response.data;
      const image_url = image_response.generations_by_pk.generated_images[0].url;
      setImageUrl(image_url); // Set the image URL state
    } catch (error) {
      console.error('Error sending data:', error);
      alert('Failed to send data.');
    } finally {
      setIsGenerating(false); // Hide spinner
    }
  };

  if (loading) return <Spinner size="xl" color="teal.500" />;

return (
  <Box p={4} color="white">
    <Heading as="h1" size="xl">{title}</Heading> {/* Use state variable */}
    <Text mt={2} fontSize="lg">Prompt for Image Generation:</Text>
    <Textarea
      mt={4}
      height="400px"
      value={textareaContent} // Use the state variable
      onChange={(e) => setTextareaContent(e.target.value)} // Add onChange handler
      color="black"
      bg="white"
      p={10}
    />
    <Box mt={4}>
      <Heading as="h2" size="md">Select a Model:</Heading>
      <select onChange={(e) => setSelectedModel(e.target.value)} value={selectedModel} style={{ color: 'black' }}>
        <option key="default" value="id">
          Leonardo Vision XL (Photo-Realistic) - Default
        </option>
        {models.length > 0 ? (
          models.map((model) => (
            <option key={model.id} value={model.id}>
              {model.name}
            </option>
          ))
        ) : (
          <option disabled>No models available</option>
        )}
      </select>
    </Box>
  <button onClick={handleSendData} style={{ marginTop: '20px', padding: '10px 20px', backgroundColor: 'teal', color: 'white', border: 'none', borderRadius: '5px' }}>
      Generate Image
    </button>
    <Box mt={4}>
    {isGenerating && <Spinner size="md" color="teal.500" mt={4} />}
{imageUrl && <img src={imageUrl} alt="Generated" style={{ marginTop: '20px', maxWidth: '100%' }} />}
        </Box>
  </Box>
);

};
export default CanonResultDetails;