import api from '../api/api';

const listCanonDrafts = async () => {
  try {
    const response = await api.get(`/canon_draft_posts`);
    return response.data;
  } catch (error) {
    console.error('Error fetching canon drafts:', error);
    throw error;
  }
};

export default listCanonDrafts;